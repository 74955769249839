import React from "react"
import Layout from "../../components/layout"
import { Helmet } from "react-helmet"

export default function Privacy() {
  return (
    <Layout>
    <Helmet>
    <meta http-equiv="refresh" content="0;url=https://www.iubenda.com/privacy-policy/13230778" />
    </Helmet>
    </Layout>
  )
}
